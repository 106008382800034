<script setup lang="ts">
import { Button } from '~/components/ui/button';

defineProps({
  error: Object as any,
});

// 遷移元のページに戻りたい
const handleError = () => clearError();
</script>

<template>
  <div class="container py-6 text-center">
    <h1 class="mb-4 text-2xl font-bold">エラー</h1>
    <p class="mb-2">エラーコード: {{ error.statusCode }}</p>
    <p class="mb-4">エラーメッセージ: {{ error.message }}</p>
    <Button
      class="mx-auto w-[200px] bg-primary"
      variant="default"
      @click="handleError"
      >Clear errors</Button
    >
  </div>
</template>
